export function initPopups() {

	let popups = document.querySelectorAll('.popup');


	const openPopupLinks = document.querySelectorAll('.open-popup');

	openPopupLinks.forEach((el) => {

		el.removeEventListener('click', openPopup);
		el.addEventListener('click', openPopup);

	});


	// Close popup when the user clicks outside the popup content
	popups.forEach((el) => {

		if (!el.classList.contains('popup--fullscreen'))
		{
			el.removeEventListener('click', closePopup);
			el.addEventListener('click', closePopup);
		}

	});


	// Close popup when the user clicks a close button outside a popup
	document.querySelectorAll('.close-popup').forEach((el) => {

		el.addEventListener('click', function (e) {
			e.preventDefault();
			e.stopPropagation();

			let popupID = this.dataset.popup;
			closePopupById(popupID);
		});

	});

}

export function openPopupById(popupID) {
	let popup = document.getElementById(popupID);

	if (popup !== null)
	{
		// close other open popups
		let openPopups = document.querySelectorAll('.popup.popup--active');

		openPopups.forEach((el) => {
			closePopupById(el.id)
		});

		popup.classList.add('popup--active');

		document.body.classList.add('popup-open');

		const openPopupEvent = new CustomEvent("openPopup", {
			detail:  {
				popup: popup
			},
			bubbles: true
		});

		popup.dispatchEvent(openPopupEvent);
		document.body.setAttribute("data-lenis-prevent", "true");
	}

	return false;
}

export function closePopupById(popupID) {
	let popup = document.getElementById(popupID);

	if (popup !== null)
	{
		popup.classList.remove('popup--active');

		document.body.classList.remove('popup-open');

		const closePopupEvent = new CustomEvent("closePopup", {
			detail:  {
				popup: popup
			},
			bubbles: true
		});

		popup.dispatchEvent(closePopupEvent);

		document.body.removeAttribute("data-lenis-prevent");
	}

	return false;
}

let openPopup = function (e) {
	e.preventDefault();

	let popupID = this.dataset.popup;

	if (popupID !== undefined)
	{
		openPopupById(popupID);
	}

	return false;
};

let closePopup = function (e) {
	e.stopPropagation();

	let popupID;
	const popup        = this;
	const popupContent = popup.querySelector('.popup__content');

	// close the popup if the user clicked outside .popup__content
	// (the target of the click isn't .popup__content nor a descendant of .popup__content)
	if (popupContent !== e.target && !popupContent.contains(e.target))
	{
		popupID = popup.id;
	}

	// close the popup if the user clicked on the popup's close button
	if (e.target.classList.contains('popup__close'))
	{
		popupID = popup.id;
	}

	if (popupID !== undefined)
	{
		closePopupById(popupID);

		return false;
	}

	// if the user clicked a link, continue the action
	return true;
};


initPopups();