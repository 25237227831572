const body = document.body;
let scrollPos = 0;

function scrollTop(el, value) {
	if (value === undefined)
	{
		return el.scrollY;
	}
	else
	{
		if (el === window || el.nodeType === 9)
		{
			el.scrollTo(el.scrollX, value);
		}
		else
		{
			el.scrollY = value;
		}
	}
}


function checkScroll() {

	if (body.classList.contains('smooth-scroll'))
	{
		return false;
	}

	// window.scrollTop() is equivalent to (document.body.getBoundingClientRect()).top with inverse sign
	let scrollDistance = 800;
	let documentScrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

	if (scrollPos !== 0)
	{
		// detects new scroll state and compares it with the old one
		if (documentScrollTop < scrollPos)
		{
			if ((documentScrollTop - scrollPos) < -20)
			{
				body.classList.add('scrolled-up');
				body.classList.remove('scrolled-down');
			}
		}
		else
		{
			body.classList.add('scrolled-down');
			body.classList.remove('scrolled-up');
		}
	}

	// saves the new position for iteration
	scrollPos = documentScrollTop;

	if (scrollPos === 0)
	{
		body.classList.remove('scrolled-up', 'scrolled-down');
	}

	if (documentScrollTop > scrollDistance)
	{
		if (!body.classList.contains('sticky-header'))
		{
			body.classList.add('to-sticky-header');
		}

		body.classList.add('sticky-header');

		if (body.classList.contains('to-sticky-header'))
		{
			setTimeout(function () {
				body.classList.remove('to-sticky-header');
			}, 50);
		}
	}
	else
	{
		if (body.classList.contains('sticky-header'))
		{
			body.classList.add('to-normal-header');
		}

		if (body.classList.contains('to-normal-header'))
		{
			setTimeout(function () {
				body.classList.remove('to-normal-header');
				body.classList.remove('sticky-header');
			}, 50);
		}
	}
}

checkScroll();


window.addEventListener('scroll', function () {

	checkScroll();

});