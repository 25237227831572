import {utils} from "./utils";

//================================= TOGGLE MENU

const body       = document.querySelector('body');
const menuToggle = document.getElementById('menu-toggle');

document.addEventListener('click', function (e) {

	// check if menuToggle exists
	if (menuToggle === null)
	{
		return;
	}

	// return if the clicked element is not #menu-toggle nor is contained in #menu-toggle
	if (e.target !== menuToggle && !menuToggle.contains(e.target))
	{
		return;
	}

	menuToggle.classList.toggle('tcon-transform');

	// open/close mobile menu
	body.classList.toggle('menu-open');

	// toggle page scrolling
	if (body.classList.contains(('menu-open')))
	{
		document.body.setAttribute("data-lenis-prevent", "true");
	}
	else
	{
		document.body.removeAttribute("data-lenis-prevent");

	}

	/*let toggleLabel = menuToggle.querySelector('.tcon-label');

	if (toggleLabel !== null)
	{
		toggleLabel.textContent = (body.classList.contains('menu-open')) ? toggleLabel.dataset.labelClose : toggleLabel.dataset.labelMenu;
	}*/
});

let menuClose = document.querySelector('#menu-close');

if (menuClose !== null)
{
	menuClose.addEventListener('click', function () {
		// close mobile menu
		body.classList.remove('menu-open');

		// allow page scrolling
		document.body.removeAttribute("data-lenis-prevent");
	});
}

// Close menu when the user clicks outside the menu
body.addEventListener('click', function (e) {
	const container = document.querySelector('.header-bar');

	// if the target of the click isn't the container nor a descendant of the container
	if (container !== e.target && !container.contains(e.target) && body.classList.contains('menu-open'))
	{
		// close mobile menu
		body.classList.remove('menu-open');

		// allow page scrolling
		body.removeAttribute("data-lenis-prevent");

		menuToggle.classList.remove('tcon-transform');
	}
});

//================================= ADD SUB-MENU TOGGLES

document.querySelectorAll('.menu-item-has-children > a').forEach((el) => {
	const toggleMenuItem = document.createElement('span');
	toggleMenuItem.classList.add('toggle_menu_item');
	el.appendChild(toggleMenuItem);

	toggleMenuItem.addEventListener('click', function (e) {
		e.preventDefault();
		e.stopPropagation();

		const menuItem = this.closest('.menu-item');

		utils.toggleClasses(menuItem, 'open');
		utils.slideToggle(menuItem.querySelector('.sub-menu'));
	});
});