import {utils} from "./utils";

/**
 * Scroll smoothly the page towards the given element
 * @param elem
 * @param offset
 */
export function goToByScroll(elem, offset) {
	if (elem === null)
	{
		return false;
	}

	if (typeof offset === 'undefined')
	{
		offset = 0;
	}

	// close mobile menu
	document.body.classList.remove('menu-open');
	document.getElementById('menu-toggle').classList.remove('tcon-transform');

	const offsetPosition = utils.getOffset(elem).top + parseInt(offset);

	// if the page is already scrolled at the target element, resolve with an empty promise
	if (Math.abs(window.scrollY - offsetPosition) <= 1)
	{
		return Promise.resolve();
	}

	document.body.classList.add('smooth-scroll');
	document.body.classList.remove('scrolled-up');
	document.body.classList.add('scrolled-down');

	window.scrollTo({
		top:      offsetPosition,
		behavior: "smooth"
	});

	document.addEventListener('smoothScrollEnd', function (){
		// do something when the scrolling ends
	});

	// promise for when the scrolling ends
	return new Promise(resolve => {
		const scrollHandler = () => {

			// resolve the promise when the page has finished scrolling to the target element
			if (Math.abs(window.scrollY - offsetPosition) <= 1)
			{
				window.removeEventListener('scroll', scrollHandler);

				setTimeout(function () {
					document.body.classList.remove('smooth-scroll');
				}, 100);

				resolve();
			}
		}

		window.addEventListener('scroll', scrollHandler);
	});
}