import {goToByScroll} from './go-to-by-scroll';


//================================= SMOOTH SCROLL

const linkToAnchors = document.querySelectorAll('a[href*="#"]');

linkToAnchors.forEach((el) => {
	el.addEventListener('click', function (e) {
		let target = this.hash;

		if (target === '')
		{
			e.preventDefault();
			return false;
		}

		let targetElement = document.querySelector(target);

		if (targetElement !== null)
		{
			e.preventDefault();

			let offset = this.dataset.offset;

			if (typeof offset === 'undefined')
			{
				//offset = -1 * document.getElementById('header').offsetHeight;
			}

			goToByScroll(targetElement, offset).then(() => {
				// trigger smoothScrollEnd event when the promise is resolved
				document.dispatchEvent(smoothScrollEndEvent);
			});

			return false;
		}
	});
});

// create custom event smoothScrollEnd
const smoothScrollEndEvent = new CustomEvent('smoothScrollEnd', {
	bubbles: true
});


window.addEventListener('load', function (event) {

	// smooth scroll to anchor on load
	if (location.hash)
	{
		//let offset = -1 * document.getElementById('header').offsetHeight; // use when header is fixed and always visible
		let offset = 0;

		setTimeout(function () {
			goToByScroll(document.querySelector(location.hash), offset);
		}, 200);
	}

});