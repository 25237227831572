import {goToByScroll} from './go-to-by-scroll';
import {bindLinkToItem} from './bind-link-to-item';
import {videoScrub, utils, vh, whichSizeVideo, startWhichSizeVideo} from "./utils";
import {initPopups, openPopupById} from "./popups";
//import { gsap } from '../libs/gsap.min';
//import { ScrollTrigger } from '../libs/ScrollTrigger.min';

let gsapMatchmedia = gsap.matchMedia();


// Initialize a new Lenis instance for smooth scrolling
const lenis = new Lenis();

// Listen for the 'scroll' event and log the event data to the console
/*lenis.on('scroll', (e) => {
	console.log(e);
});*/

// Synchronize Lenis scrolling with GSAP's ScrollTrigger plugin
lenis.on('scroll', ScrollTrigger.update);

// Add Lenis's requestAnimationFrame (raf) method to GSAP's ticker
// This ensures Lenis's smooth scroll animation updates on each GSAP tick
gsap.ticker.add((time) => {
	lenis.raf(time * 1000); // Convert time from seconds to milliseconds
});

// Disable lag smoothing in GSAP to prevent any delay in scroll animations
gsap.ticker.lagSmoothing(0);


/**
 * Images parallax
 */
gsap.utils.toArray('.parallax-img-container').forEach(function (container) {
	const image = container.querySelector('img');

	gsap.to(image, {
		y:             () => image.offsetHeight - container.offsetHeight,
		ease:          'none',
		scrollTrigger: {
			trigger: container,
			scrub:   true,
			pin:     false,
			//markers: true,
			//invalidateOnRefresh: true
		},
	});
});


/**
 * Animate the .header-bar border
 */
gsap.to('.header-bar', {
	'--border-width': '100%',
	ease:             'none',
	scrollTrigger:    {
		trigger: 'body',
		scrub:   true,
		start:   () => 'top top',
		end:     () => 'bottom bottom',
		//markers: true,
		//invalidateOnRefresh: true
	},
});


if (document.querySelector('.header-bar__final-page'))
{
	ScrollTrigger.create({
		trigger:     '#content',
		start:       () => 'bottom bottom',
		end:         () => '+=110%',
		toggleClass: {
			targets:   '#header, .header-bar',
			className: 'show'
		},
		//markers:  true
	});
}


/**
 * Toggle sub menu when clicking on the whole anchor
 */
document.querySelectorAll('.menu-item-has-children > a').forEach((el) => {

	el.addEventListener('click', function (e) {
		e.preventDefault();
		e.stopPropagation();

		const toggleMenuItem = el.querySelector('.toggle_menu_item');

		toggleMenuItem.click();
	});
});


/**
 * Drag to scroll
 */
const heroSoluzioniItemGrids = document.querySelectorAll('.hero-soluzioni-item__grid');

heroSoluzioniItemGrids.forEach((el) => {
	new ScrollBooster({
		viewport:   el,
		scrollMode: 'transform',
		direction:  'horizontal',
		bounce:     false
	});
});

const moduleCardsSectionRowWrappers = document.querySelectorAll('.module-cards-section .row-wrapper');

moduleCardsSectionRowWrappers.forEach((el) => {
	new ScrollBooster({
		viewport:   el,
		scrollMode: 'transform',
		direction:  'horizontal',
		bounce:     false
	});
});

const moduleBlocksSectionRowWrappers = document.querySelectorAll('.module-blocks-section .row-wrapper');

moduleBlocksSectionRowWrappers.forEach((el) => {
	new ScrollBooster({
		viewport:   el,
		scrollMode: 'transform',
		direction:  'horizontal',
		bounce:     false
	});
});

const moduleRelatedContentRowWrappers = document.querySelectorAll('.module-related-content .row-wrapper');

moduleRelatedContentRowWrappers.forEach((el) => {
	new ScrollBooster({
		viewport:   el,
		scrollMode: 'transform',
		direction:  'horizontal',
		bounce:     false
	});
});

const moduleTeamRowWrappers = document.querySelectorAll('.module-team .row-wrapper');

moduleTeamRowWrappers.forEach((el) => {
	new ScrollBooster({
		viewport:   el,
		scrollMode: 'transform',
		direction:  'horizontal',
		bounce:     false
	});
});


/**
 * Wrap tables and add horizontal scroll
 * @type {NodeListOf<Element>}
 */
const tables = document.querySelectorAll('.entry-content table');

tables.forEach((el) => {

	const wrapper = document.createElement('div');
	wrapper.classList.add('table-wrapper');

	utils.wrap(el, wrapper);

	new ScrollBooster({
		viewport:   el.parentNode,
		scrollMode: 'transform',
		direction:  'horizontal',
		bounce:     false
	});
});


const heroSoluzioneTitle1 = document.querySelector('.page-template-template-soluzione .hero-soluzione .title span:nth-child(1)');

if (heroSoluzioneTitle1 !== null)
{
	const heroSoluzioneTitle2 = heroSoluzioneTitle1.nextElementSibling;

	function setMarginBottom() {
		heroSoluzioneTitle1.style.marginBottom = 'calc(24px + ' + heroSoluzioneTitle2.offsetHeight + 'px - ' + heroSoluzioneTitle1.offsetHeight / 2 + 'px)';
	}

	setMarginBottom();

	window.addEventListener('resizeHorizontallyEnd', setMarginBottom);
}



/**
 * Module altre soluzioni
 * @type {NodeListOf<Element>}
 */
const moduleAltreSoluzioniVideos = document.querySelectorAll('.module-altre-soluzioni video');

moduleAltreSoluzioniVideos.forEach((el, i) => {

	if (matchMedia('(any-pointer: coarse)').matches)
	{
		el.setAttribute('autoplay', 'true');
	}
	else
	{
		el.addEventListener('mouseover', function () {
			this.play();
		});

		el.addEventListener('mouseleave', function () {
			this.pause();
		});
	}
});


/**
 * Pin Soluzione Dettaglio Intro image
 * @type {Element}
 */
const soluzioneDettaglioIntro = document.querySelector('.page-template-template-soluzione-dettaglio .intro');

if (soluzioneDettaglioIntro !== null)
{
	ScrollTrigger.create({
		trigger: soluzioneDettaglioIntro,
		pin:     soluzioneDettaglioIntro.querySelector('.intro__bg'),
		start:   () => 'top top',
		end:     () => 'bottom bottom',
		//markers:     true
	});

	gsap.to('.intro__bg', {
		'--bg-opacity': 1,
		ease:           'none',
		scrollTrigger:  {
			trigger: soluzioneDettaglioIntro,
			start:   () => 'top top',
			end:     () => 'bottom bottom',
			scrub:   true,
			//markers:     true
		},
	});

}


/**
 * Hero home parallax
 */
gsap.utils.toArray('.hero-soluzioni-item').forEach(function (container) {

	startWhichSizeVideo(container);

	gsapMatchmedia.add({
		isDesktop: '(min-width: 992px)',
		isTablet:  '(min-width: 768px) and (max-width: 991.98px)',
		isMobile:  '(max-width: 767.98px)'

	}, (context) => {

		let {isDesktop, isTablet, isMobile} = context.conditions;

		// section snap
		ScrollTrigger.create({
			trigger: container,
			start:   () => 'top top',
			end:     () => 'bottom top',
			scrub:   true,
			snap:    isDesktop ? {
				// snap to most visible section
				snapTo(self) {
					if (self < 0.5)
					{
						return 0;
					}
					else
					{
						return 1;
					}
				},
				duration: 0.3,
				delay:    0,
				ease:     "power1.inOut"
			} : false,
			//markers:             true,
			//invalidateOnRefresh: true
		});


		// image parallax
		const media  = container.querySelector('img, video');
		const isHalf = media.parentNode.classList.contains('hero-soluzioni-item--half');

		gsap.set(media, {
			scale: isMobile ? 1.15 : 1.1,
			y:     isHalf && isMobile ? -80 : -200
		});

		gsap.to(media, {
			y:             isHalf && isMobile ? 80 : 200,
			ease:          'none',
			scrollTrigger: {
				trigger: container,
				start:   () => 'top bottom',
				end:     () => 'bottom top',
				scrub:   true,
				//markers:             true,
				//invalidateOnRefresh: true
			},
		});

	});

});


/**
 * Reveal text on scroll
 */
const revealTextOnScrollItems = gsap.utils.toArray(".reveal-text-on-scroll");

revealTextOnScrollItems.forEach((item) => {

	let text = item.textContent;

	item.innerHTML = text.replace(/[a-zA-Z0-9òàùèéì,;.!?(){}[\]:"'’<>-]/g, '<span>$&</span>');

	const spans = item.querySelectorAll("span");

	gsap
		.timeline({
			scrollTrigger: {
				trigger: item,
				scrub:   1,
				start:   "top bottom",
				end:     "bottom center",
				//markers: true
			}
		})
		.from(spans, {
			opacity:  0.2,
			duration: 1,
			ease:     "none",
			stagger:  0.1
		});
});


/**
 * Sticky cards
 */
const stickyCardsItems      = [...document.querySelectorAll('.module-sezione-sticky-cards__card')];
const totalStickyCardsItems = stickyCardsItems.length;

stickyCardsItems.forEach((card, position) => {

	const isLast = position === totalStickyCardsItems - 1;

	gsap.timeline({
		scrollTrigger: {
			trigger: card,
			start:   'center center',
			end:     '+=100%',
			scrub:   1
		}
	})
		.to(card, {
			ease:     'none',
			scale:    0.6,
			opacity:  0,
			yPercent: isLast ? 50 : 0
		}, 0);

});


const moduleFasceScrollTopDivs = gsap.utils.toArray('.module-fasce-scroll-top');

moduleFasceScrollTopDivs.forEach(function (container, i) {

	const panels = container.querySelectorAll('.module-fasce-scroll-top__panel');

	// set z-index in reverse order
	gsap.set(panels, {
		zIndex: (i, target, targets) => targets.length - i
	});

	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: container,
			start:   "top top",
			end:     () => '+=' + ((panels.length - 1) * 100) + '%', // duration = height of all panels - 1
			scrub:   true,
			pin:     true,
			//markers: true
		}
	});

	panels.forEach((panel, i) => {
		if (i !== panels.length - 1) // exclude last panel
		{
			// slide panel up
			tl.to(panel, {
				yPercent: -100,
				ease:     "none"
			});

			// fade out overlay in the next panel
			tl.to(panels[i + 1], {
					'--shadow-opacity': 0,
					ease:               "none"
				},
				'<');
		}

	});

});


const indexSidebar = document.querySelector(".index-sidebar");

if (indexSidebar !== null)
{
	const indexSidebarTitle    = indexSidebar.querySelector(".index-sidebar__title");
	const indexSidebarNav      = indexSidebar.querySelector(".index-sidebar__nav");
	const indexSidebarNavLinks = gsap.utils.toArray(".index-sidebar__nav a");
	const sections             = gsap.utils.toArray(".section");

	indexSidebarTitle.childNodes[0].nodeValue = indexSidebarNavLinks[0].textContent;

	sections.forEach((section, i) => {
		ScrollTrigger.create({
			trigger:     section,
			start:       "top 50%",
			end:         "bottom 50%",
			onEnter:     () => activateIndexItem(i),
			onEnterBack: () => activateIndexItem(i),
			//markers:     true
		});
	});

	function activateIndexItem(i) {
		indexSidebarNavLinks.forEach((e) => {
			e.classList.remove("active");
		});

		indexSidebarNavLinks[i].classList.add("active");
		indexSidebarTitle.childNodes[0].nodeValue = indexSidebarNavLinks[i].textContent;
	}

	indexSidebarTitle.addEventListener('click', function () {
		if (matchMedia('(max-width: 991.98px)').matches)
		{
			utils.slideToggle(indexSidebarNav);
			indexSidebarTitle.classList.toggle('active');
		}
	});

	indexSidebarNavLinks.forEach((e) => {
		e.addEventListener('click', function () {
			if (matchMedia('(max-width: 991.98px)').matches)
			{
				utils.slideUp(indexSidebarNav);
				indexSidebarTitle.classList.remove('active');
			}
		});
	});
}


/**
 * Load more posts via ajax on click
 */
const loadMorePosts = function (e) {
	e.preventDefault();

	const loading = utils.prev(loadMoreButton, '.loading');

	loading.classList.add('loading--visible');

	const data = new FormData();
	data.append('action', 'wc_do_ajax');
	data.append('fn', 'load_more_posts');
	data.append('nonce', ajax_vars.nonce);
	data.append('query', ajax_vars.posts);
	data.append('page', ajax_vars.current_page);

	fetch(ajax_vars.ajax_url, {
		method:      'POST',
		credentials: 'same-origin',
		body:        data
	})
		.then((response) => response.text())
		.then((data) => {
			if (data)
			{
				const parsedData = JSON.parse(data);

				if (parsedData.result === 'success')
				{
					ajax_vars.current_page++;

					const postsContainer = document.querySelector('.posts-list');

					const parser     = new DOMParser();
					const doc        = parser.parseFromString(parsedData.html, 'text/html');
					const nodes      = doc.body.childNodes;
					const nodesArray = Array.from(nodes);

					for (let i = 0; i < nodesArray.length; i++)
					{
						postsContainer.appendChild(nodesArray[i]);
					}

					bindLinkToItem('.link-all-item');

					initPopups();

					if (ajax_vars.current_page === ajax_vars.max_page)
					{
						loadMoreButton.remove() // if last page, remove the button
					}
				}
			}
			else
			{
				loadMoreButton.remove() // if no data, remove the button as well
			}

			loading.classList.remove('loading--visible');
		})
		.catch((error) => {
			console.error(error);
		});
};

const loadMoreButton = document.querySelector('.load-more-posts');

if (loadMoreButton !== null)
{
	loadMoreButton.removeEventListener('click', loadMorePosts);
	loadMoreButton.addEventListener('click', loadMorePosts);
}


/**
 * Show Contatti form's hidden fiels only when both selects are filled in
 * @type {NodeListOf<Element>}
 */
const mandatorySelects = document.querySelectorAll('#form-contatti select.mandatory');
const hiddenFields     = document.querySelector('#form-contatti .hidden-fields');

mandatorySelects.forEach((el) => {

	el.addEventListener('change', function (e) {
		let allFilledIn = true;

		mandatorySelects.forEach((el2) => {
			if (el2.value === '')
			{
				allFilledIn = false;
			}
		});

		if (allFilledIn === true)
		{
			utils.slideDown(hiddenFields);
		}
		else
		{
			utils.slideUp(hiddenFields);
		}
	});
});


/**
 * Accept terms and conditions before downloading a file
 * @type {NodeListOf<Element>}
 */
const downloadProtettoModules = document.querySelectorAll('.module-download-protetto');

downloadProtettoModules.forEach((module) => {
	const toggle = module.querySelector('input.toggle');
	const links  = module.querySelectorAll('.module-download-protetto__files a');

	links.forEach((link) => {
		link.classList.add('disabled');
	});

	toggle.addEventListener('change', function (e) {
		e.stopPropagation();

		if (toggle.checked)
		{
			links.forEach((link) => {
				link.classList.remove('disabled');
			});
		}
		else
		{
			links.forEach((link) => {
				link.classList.add('disabled');
			});
		}
	});
});


/**
 * Scrub video on scroll and animate texts
 * @type {NodeListOf<Element>}
 */
const videoOnScrollModules = document.querySelectorAll('.module-video-on-scroll');

videoOnScrollModules.forEach((module) => {
	const video        = module.querySelector('video');
	const texts        = module.querySelectorAll('.text');
	const showProgress = module.dataset.showProgress;
	let debug          = null;
	let timings        = [];

	if (video !== null)
	{
		let scrollLength = video.dataset.scrollLength;

		if (scrollLength === '')
		{
			scrollLength = 1000;
		}

		if (showProgress === 'true')
		{
			debug = document.createElement('div');
			debug.classList.add('debug');
			module.querySelector('.wrapper').appendChild(debug);
		}

		whichSizeVideo(video);

		// scrolltrigger settings
		let videoScrollTriggerArgs = {
			trigger: module,
			start:   () => "top top",
			end:     () => '+=' + vh(scrollLength - 100),
			scrub:   true
			//markers: true
		};

		if (showProgress === 'true')
		{
			videoScrollTriggerArgs.onUpdate = (self) => debug.textContent = "progress: " + (self.progress * 100).toFixed(3);
		}

		videoScrub(video, {
			scrollTrigger: videoScrollTriggerArgs,
			//onUpdate: () => console.log(video.currentTime)
		});

		// set up the timeline to animate texts with the same scrolltrigger of the video
		const textTimeline = gsap.timeline({
			scrollTrigger: videoScrollTriggerArgs
		});

		// push the timings of each text in the timings array
		texts.forEach(function (item, i) {
			const start = parseFloat(item.dataset.timingStart);
			const end   = parseFloat(item.dataset.timingEnd);

			timings.push([start, end]);
		});

		// add the animation of each text to the timeline
		texts.forEach(function (item, i) {
			let duration;
			const target = item;
			const start  = timings[i][0];
			const end    = timings[i][1];

			// empty space between texts
			if (i > 0)
			{
				const previousEnd = timings[i - 1][1];

				duration = start - previousEnd;

				textTimeline.to({}, {duration: duration});
			}

			// show text: fade out for the first, fade in and fade out for the others
			duration = end - start;

			if (i === 0)
			{
				textTimeline.to(target, {
					duration: duration,
					opacity:  0,
					ease:     'power2.in',
				})
			}
			else
			{
				textTimeline.to(target, {
					duration: duration / 2, // half duration because the animation is twice as long due to repeat: 1
					opacity:  1,
					ease:     'none',
					repeat:   1,
					yoyo:     true
				})
			}

			// empty space after last text
			if (i === timings.length - 1)
			{
				duration = 100 - end;

				textTimeline.to({}, {duration: duration});
			}
		});

	}

});


// Reload prodotto page when orientation change
if (document.body.classList.contains('single-prodotto'))
{
	window.addEventListener("orientationchange", function () {
		window.location.reload();
	});
}


/**
 * Archive case history header: show images at random
 * @type {Element}
 */
const archiveCaseHistoryHeader = document.querySelector('.archive-case-history-header');

if (archiveCaseHistoryHeader)
{
	const archiveCaseHistoryHeaderTitle = archiveCaseHistoryHeader.querySelector('.archive-case-history-header__title');
	const text                          = archiveCaseHistoryHeaderTitle.textContent;
	const interval                      = 1500;

	archiveCaseHistoryHeaderTitle.innerHTML = text.replace(/[a-zA-Z0-9òàùèéì,;.!?(){}[\]:"'’<>-]/g, '<span>$&</span>');

	setInterval(showRandomImage, interval);

	showRandomImage()

	function showRandomImage() {
		const images = archiveCaseHistoryHeader.querySelectorAll('img:not(.visible)');

		if (images.length > 0)
		{
			const newVisibleImage = images[Math.floor(Math.random() * (images.length))];

			newVisibleImage.classList.add('visible');

			setTimeout(function () {
				newVisibleImage.classList.remove('visible');
			}, interval * 3);
		}
	}
}


/**
 * Numbers appear from the bottom animation
 */
const numeros = gsap.utils.toArray('.module-numeri')

numeros.forEach(function (item, i) {

	const innerItem = item.querySelectorAll('.module-numeri__numero div');

	gsap.from(innerItem, {
		y:             '100%',
		ease:          'power4.inOut',
		duration:      1.2,
		stagger:       0.05,
		scrollTrigger: {
			trigger: item,
			start:   'top bottom',
			end:     'bottom bottom',
			once:    true,
			//markers: true
		},
	});
});


/**
 *  Counter animation
 */
const countNumbers = gsap.utils.toArray('.counter')

countNumbers.forEach(function (item, i) {
	const speed = 25; // lower is faster

	setTimeout(function () {

		ScrollTrigger.create({
			trigger: item,
			start:   'top bottom',
			end:     'bottom bottom',
			once:    true,
			//markers: true,
			onEnter: () => {
				let number = item.textContent;
				number     = cleanNumber(number);

				item.innerText = 0;

				if (number !== '')
				{
					const animate = () => {
						const value = +cleanNumber(number);
						const data  = +cleanNumber(item.innerText);
						const time  = value / speed;

						if (data < value)
						{
							item.innerText = formatNumber(Math.ceil(data + time));
							setTimeout(animate, 40);
						}
						else
						{
							item.innerText = formatNumber(value);
						}

					}

					animate();
				}
			}
		});
	}, 100);

});

function formatNumber(number) {
	return new Intl.NumberFormat("it-IT").format(number);
}

function cleanNumber(number) {
	return number.replace(/[^0-9]+/g, '');
}


/**
 * Numbers appear from the bottom animation
 */
const moduleSlideshowTimelineSlides = document.querySelectorAll('.module-slideshow-timeline .swiper-slide')

moduleSlideshowTimelineSlides.forEach(function (item, i) {

	item.addEventListener('click', function () {
		if (matchMedia('(pointer: coarse)').matches)
		{
			this.classList.toggle('active');
		}
	})
});



/**
 * Language switcher
 */

document.querySelector('.lang-selector--header .lang-selector__lang--active').addEventListener('click', function () {
	this.parentNode.classList.toggle('lang-selector--active');
});

document.querySelector('.lang-selector--header .lang-selector__list').addEventListener('mouseleave', function () {
	this.parentNode.classList.remove('lang-selector--active');
});

// language switcher inside menu
document.querySelector('.lang-selector--menu .lang-selector__lang--active').addEventListener('click', function () {
	this.parentNode.classList.toggle('lang-selector--active');

	utils.slideToggle(document.querySelector('.lang-selector--menu .lang-selector__list'));
});


const pageTitle = document.title;
const pageUrl = window.location.href.split('?')[0];

/**
 * Open post popup when loading the blog page with the post-id url argument
 */
if (document.body.classList.contains('blog'))
{
	const params  = new URLSearchParams(window.location.search);
	const popupID = params.get('post-id');

	if (popupID !== null)
	{
		const popup = document.getElementById('popup-post-' + popupID);

		// change url and title in browser
		const permalink = popup.dataset.permalink;
		const title     = popup.dataset.title;
		history.pushState('', '', permalink);
		document.title = title;

		// open popup
		openPopupById('popup-post-' + popupID);
	}
}


/**
 * Change url and title when opening a news popup
 */
document.body.addEventListener('openPopup', function (e) {
	const popup = e.detail.popup;

	// change url and title in browser
	const permalink = popup.dataset.permalink;
	const title     = popup.dataset.title;

	if (permalink !== null && permalink !== title)
	{
		history.pushState('', '', permalink);
		document.title = title;
	}
});

document.body.addEventListener('closePopup', function (e) {

	history.pushState('', '', pageUrl);
	document.title = pageTitle;

});


const moduleCardsSections = document.querySelectorAll('.module-cards-section');

moduleCardsSections.forEach((item) => {
	const cards = item.querySelectorAll(".card");

	if (matchMedia('(min-width: 992px)').matches)
	{
		gsap
			.timeline({
				scrollTrigger: {
					trigger: item,
					scrub:   1,
					start:   "top bottom",
					end:     "bottom bottom",
					//markers: true
				}
			})
			.from(cards, {
				y:        400,
				ease:     "none",
				duration: 1,
				stagger:  0.1
			});
	}
});



/**
 * ON LOAD
 */

window.addEventListener('load', function (event) {

	ScrollTrigger.getAll().forEach(t => t.refresh());

});



/**
 * ON RESIZE
 */

let prevWindowWidth = window.innerWidth;

window.addEventListener('resize', function (event) {
	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const event = new CustomEvent('resizeEnd');
			window.dispatchEvent(event);

			const windowWidth = window.innerWidth;

			if (windowWidth !== prevWindowWidth)
			{
				prevWindowWidth = windowWidth;

				const eventH = new CustomEvent('resizeHorizontallyEnd');
				window.dispatchEvent(eventH);
			}
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
window.addEventListener('resizeEnd', function () {

	//ScrollTrigger.getAll().forEach(t => t.refresh());

});

window.addEventListener('resizeHorizontallyEnd', function () {

	ScrollTrigger.getAll().forEach(t => t.refresh());

});


/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/